footer{
    background-color: #000;
    padding: 0px;

    .container-fluid{
        @media screen and (min-width: 1100px){
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
    .row{
        @include phone-580{
            margin-left: -45px;
             margin-right: -45px;
        }
    }

    .padding-x2{
        @include screen-767{
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }
    ul.f-menu {
        text-align: center;
        overflow: hidden;

        @include screen-767{
            margin-top: 30px;
        }

        li{
            display: inline-block;
            margin-left: -4px;

            @include screen-767{
                display: block;
                margin-bottom: 5px;
            }

            a{
                padding: 5px 20px;
                font-size: 1.3rem;
                font-weight: 500;
                transition: all 0.4s ease-in;
                color: $white;
                transition: all 0.3s ease-in;

                @include desktop-1366{
                    font-size: 1.1rem;
                    padding: 5px 10px;
                }

                @include screen-767{
                    font-size: 1.25rem;
                }

                &:hover{
                    color: $primary-color-light;
                }

                &.opacity{
                    opacity: 0.1;
                }
            }
        }
    }
    .footer-map iframe {
        @include desktop-1366{
            height: 225px;
        }
    }
    .footer-social{
        ul{
            @include screen-767{
                margin-bottom: 30px;
            }
            text-align: center;
            overflow: hidden;
            li{
                display: inline-block;
                margin-left: -4px;
                overflow: hidden;
                //transform: translateY(-100px);

                a{
                    padding: 5px 20px;
                    transition: all 0.4s ease-in;

                    &.opacity{
                        opacity: 0.1;
                    }
                    svg{
                        transition: all 0.5s ease;
                    }

                    &:hover{
                        svg{
                            fill: $primary-color;
                        }
                        
                    }
                }
            }
        }
    }
    .footer-logo{
        overflow: hidden; 
        position: relative;

        div{
            overflow: hidden;
            margin: 0 auto;
            display: block;
            transform-origin: left;
            position: relative;
        }

        a{
            overflow: hidden;
            position: relative;
        }
    }
    .footer-logo img {
        max-width: 230px;
        margin: 0 auto;
        display: block;

        
    @include desktop-1366{
        max-width: 180px;
      }

    }

    .footer-address{
        display: block;
        margin: 30px auto;
        overflow: hidden;
        a{
            max-width: 280px;
            width: 100%;
            margin: 0 auto;
            display: block;
            padding: 10px;
            font-size: 1.25rem;
            font-weight: 500;
            transition: all .4s ease-in;
            text-align: center;
            color: $white;

            &:hover{
                color: $primary-color;

                svg{
                    fill: $primary-color;
                }
            }
            span{
                margin-right: 10px;
            }
            svg{
                width: 20px;

                &:hover{
                    fill: $primary-color;
                }
            }

        }

    }
    svg{
        width: 30px;
        height: auto;
        fill: #fff;
    }
}

.footer-copyright{
    .col-md-12{
        padding: 0;
    }
}