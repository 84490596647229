/* back to top */
.progress-wrap {
    position: fixed;
    right: 20px;
    bottom: 30px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.2);
    z-index: 10000;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap.active-progress {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  .progress-wrap::after {
    position: absolute;
    content: "\f100";
    font-family: "Flaticon";
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    color: #c57f13;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 1;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap:hover::after {
    opacity: 1;
  }
  
  .progress-wrap::before {
    position: absolute;
    content: "\f100";
    font-family: "Flaticon";
    text-align: center;
    line-height: 46px;
    font-size: 20px;
    opacity: 0;
    /* background-image: linear-gradient(298deg, #da2c4d, #f8ab37); */
    -webkit-text-fill-color: transparent;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    z-index: 2;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .progress-wrap:hover::before {
    opacity: 1;
  }
  
  .progress-wrap svg path {
    fill: none;
  }
  
  .progress-wrap svg.progress-circle path {
    stroke: #c57f13;
    stroke-width: 4;
    box-sizing: border-box;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }