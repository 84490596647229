.contact_block{
    .contact-wrapper {
        display: grid;
        grid-template-columns: 2fr 3fr;
        align-items: center;
        height:85vh;

        @include desktop-1366{
            height: 100%;
        }
        @include desktop-1230{
            max-width: 760px;
            margin: 0 auto;
            grid-template-columns: auto;

            .contact-form{
                order: 2;
            }
        }
        @include screen-767{
            grid-template-columns: auto;
            grid-gap: 30px;

        }

        .text-wrap {
            background: url(../assets/images/contact.jpg) no-repeat;
            background-position: 0;
            background-size: cover;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 80px;
            height: 100%;

            @include desktop-1230{
                margin-bottom: 80px;
                height: 450px;
                order: 1;
            }

            @include screen-767{
                height: 350px;
            }

            h2 {
                font-size: 6rem;
                font-weight: 600;
                width: 70%;
            }
        }

        .contact-title{
            height: 100%;
            .contact-text-bg-wrap{
                overflow: hidden;
                height: 100%;
                position: relative;

                .contact-bg-overlay {
                    // background: #fff;
                    position: absolute;
                    right:  0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }


            }
        }

        .form-wrapper {
            background: #fff;
            padding: 30px;
            position: relative;
            transition: all .4s;
            border: 1px solid #ccc;
            z-index: 9;
            margin-right: -60px;
            overflow: hidden;

            .form-heading{
                display: flex;
                width: 100%;

                @include screen-767{
                    display: block;
                }
               .title{
                   margin-right: 25px;
                h2{
                    color: #cfa670;
                    font-size: 70px;
                    line-height: 50px;
                    font-family: 'Kristi', sans-serif;
                }
                h3{
                    font-size: 60px;
                    color: #1a3853;
                    letter-spacing: -4px;
                    font-weight: 300;
                }
               }
            }
            .address-box{
                display: flex;
                text-align: left;
                align-items: center;
                padding: 0 30px;
                width: 100%;
                background: #fff;
                justify-content: center;
                border-left: 1px solid #cfa670;

                @include screen-767{
                    border: none;
                    border-top: 1px solid #cfa670;
                    padding-top: 20px;
                    margin-top: 15px;
                }

                ul{
                    li{
                        display: flex;
                        margin-bottom: 5px;
                        a{
                            transition: all 0.5s ease;
                            &:hover{
                                color: #b67e35;;
                            }
                        }
                        span{
                            height: 100%;
                            width: 40px;
                            img{
                                max-width: 25px;
                            }
                        }

                        @include screen-767{
                            a{
                               
                            }
                        }
                    
                    }
                }

            }

            @include desktop-1366{
                margin-right: 0;
            }
            @include screen-767{
                margin-left: 0;
                padding: 40px 15px;
            }

            .contact-address {
                display: flex;
                text-align: center;
                width: 100%;
                left: 0;
                right: 0;
                transition: 0.5s all ease;

                
                @include screen-767{
                    top: 0;
                    position: relative;
                }
                .c-box{
                    display: flex;
                    flex-direction: column;

                    p{margin: 0;}
                    a{
                        font-size: 1.5rem;
                        font-weight: 500;
                        transition: all 0.4s ease;

                        @include desktop-1366{
                            font-size: 1.25rem;
                        }

                        &:hover{
                            color: $primary-color;
                        }
                    }

                    img{
                        max-width: 40px;
                        height: auto;
                        width: 100%;
                        margin-bottom: 10px;
                        transition: all 0.4s ease-in;

                        @include desktop-1366{
                            max-width: 30px;
                        }
                    }

                    &:hover img{
                        transform: scale(1.1);
                    }
                }

            }
            h2.form-title {
                font-size: 3rem;
                text-align: center;
                margin: 30px 0 10px;
                color: #666;
                overflow: hidden;

                @include screen-767{
                    margin: 30px 0;
                    font-size: 2rem;
                }
            }

            form{
                input, textarea, input:focus{
                    width: 100%;
                    background: transparent;
                    margin-bottom: 15px;
                    border: none;
                    border-bottom: 1px solid rgba(33, 33, 33, 0.2);
                    padding: 10px 0;
                    outline: none;
                }

                textarea{
                    height: 110px;
                    border: 1px solid rgba(33, 33, 33, 0.2);
                    padding: 5px;
                    margin-bottom: 5px;
                }
                #sent_btn{
                    max-width: 200px;
                    width: 100%;
                    margin: 0 auto;
                    display: block;
                    border-radius: 5px;
                    font-size: 1.2rem;
                    border: 1px solid rgba(33, 33, 33, 0.2);
                    transition: all 0.4s ease;
                    cursor: pointer;
                    color: #fff;
                    transition: all 0.5s ease;
                    background-color: $primary-color;
                    text-transform: uppercase;
                    &:hover{
                        background-color: #b67e35;
                    }
                }
            }

 
        }


}
}
.g-recaptcha {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: block;
    @media screen and (max-width: 767px){
      margin-top: 10px;
    }
  }
  .g-recaptcha>div {
    margin: 0px auto;
  }
  div#captcha_error {
    /* background: red; */
    display: inline-block;
    width: 100%;
    text-align: center;
    transition: all 0.5s;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 1.15rem;
    color: red;
  }
  .input-box-col-2>div {
    position: relative;
  }
  #contact-wrap .contact-form-wrap .form-wrapper .input-box-col-1.messageBox {
    position: relative;

    label.error{
        top: auto;
        bottom: 0;
    }
}
.country-title {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 10px;
}

    .country-title span {
        font-size: 1.8rem;
        font-weight: 600;
        padding: 15px 5px;
        display: block;
        letter-spacing: 0px;
        color: #e96f26;
    }
    form label.error{
        top: -10px;
    }
    .contact-confirmation {
        margin-top: 50px;
        padding: 20px 5px;
    }
    h3#error_message{
        color: #33a20f!important;
        font-weight: 400;
        font-size: 1.8rem;
        line-height: 44px;
    }