// Header Slider
#home-slider{
    height: 85vh;
    display: grid;
    align-items: center;
    justify-content: center;

    .header-title{
        h1{
            color: rgb(255, 252, 224);
            font-size: 65px;
            letter-spacing: -3px;
            text-transform: uppercase;
            font-family: 'Carme', sans-serif;
            text-align: center;
            span{
                display: block;
                font-family: 'Kristi', sans-serif;
                color: rgb(229, 201, 119);
                font-size: 100px;
                line-height: 50px;
                text-transform: initial;

                @include phone-580{
                    font-size: 70px;
                }

            }
            @include phone-580{
                font-size: 50px;
            }
        }
    }

    .bg{
        background: url(../assets/images/Seafood.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
   
}

// Person block

.person_block{

    .person-text{
        display: flex;
        height: 100%;
        align-items: center;

        @include screen-767{
            margin-top: 30px;
        }
    }
    h3{
        font-family: 'Kristi', sans-serif;
        color: #cfa670;
        line-height: 50px;
        font-size: 70px;

        @include screen-767{
            text-align: center;
        }
    }
    h1{
        color: #1a3853;
        letter-spacing: -4px;
        font-weight: 300;
        font-size: 60px;
        @include screen-767{
            font-size: 42px;
            text-align: center;
        }
    }
    h4{
        color: #daa259;
        font-size: 1rem;
        margin: 10px 0 20px 0;
        letter-spacing: 2px;
    }
    .person-photo {
        img{
            max-width: 100%;
        }
    }

    @include desktop-1230{
        .col-lg-6 {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }
}