nav#main-nav {
	position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 5%;

    @include desktop-1230{
        padding: 5px 2%;
    }
    @include screen-767{
        top: 40px;
    }

    &.fix-nav{
        box-shadow: 0 2px 10px #ccc;
    }
    .nav-brand img{
        max-width: 170px;
        padding: 10px 0;

        @include desktop-1230{
            max-width: 130px;
        }

        @include phone-580{
            max-width: 100px;
        }
        }
        a.mobile-reservation {
            background: #cfa670;
            display: inline-block;
            padding: 5px 10px;
            border-radius: 3px;
            margin-right: 60px;
            color: #fff;
        }
    ul.nav-items{
        @include screen-767{
            position: fixed;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: #ccc;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            transform: translateX(100%);
            transition: all 0.5s ease-in-out;

            &.show{
                transform: translate(0);
            }
        }
        li{
            display: inline-block;
            a{
                color: #444;
                font-size: 1.35rem;
                display: inline-block;
                padding: 5px 25px;
                transition: all 0.5s ease;

                &:hover{
                    color: #b67e35;
                }

                @include desktop-1230{
                    padding: 5px 15px;
                }

            }

            &#reserve-btn{
                a{
                    background: #cfa670;
                    color: #fff;
                    border: 1px solid #cfa670;
                    border-radius: 3px;

                    &:hover{
                        background: #b67e35;
                        color: #fff;
                    }
                }
                @include screen-767{
                    margin-top: 30px;
                }
            }
        }
    }
}

.mobile-top-bar {
    @include screen-767{
        background: #cfa670;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 9;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        padding: 0 5%;
        .top-address, .top-social-icon{display: flex;}
        a{
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0 15px;

            i{
                color: #fff;
                font-size: 22px;
            }
        }
    }
}

