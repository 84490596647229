/* Gilroy */
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Light.ttf") format("truetype");
  font-style: normal;
  font-display:swap;
  font-weight: 300;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Regular.ttf") format("truetype");
  font-style: normal;
  font-display:swap;
  font-weight: 400;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display:swap;
}
@font-face {
  font-family: "Gilroy";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Gilroy/Gilroy-Bold.ttf") format("truetype");
  font-style: normal;
  font-display:swap;
  font-weight: 700;
}
// avenir
@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 300;
  src: local('AvenirLTStd-Light'), url('https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Light.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: local('AvenirLTStd-Book'), url('https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Book.otf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: local('AvenirLTStd-Roman'), url('https://cdn.web-dynamic.gr/common/fonts/Avenir/AvenirLTStd-Roman.otf') format('truetype');
  font-display: swap;
}

// open sans
@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display:swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display:swap;
}

@font-face {
  font-family: "Open Sans";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display:swap;
}
//beyond_the_mountains
@font-face {
  font-family: "Beyond";
  src: url("../fonts/beyond_the_mountains.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display:swap;
}
@font-face {
  font-family: "Beyond";
  src: url("../fonts/beyond_the_mountains.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display:swap;
}


/* Back-to-button fonts */
@font-face {
  font-family: "Flaticon";
  src: url("https://cdn.web-dynamic.gr/common/fonts/Flaticon/Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display:swap;
}
.flaticon-up-arrow:before { content: "\f100";font-display:swap; }