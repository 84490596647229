/* Form Modal Style */
div#Booking_btn {
    background: rgba(227, 224, 221, .8);
  }
  .request-form label {
    display: inline-block;
    font-size: 1.3em;
    line-height: 1em;
    letter-spacing: 0.04em;
    margin-bottom: 5px;
    /* padding: 0 0 0 15px; */
    color: #fff;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    margin-top: 20px;
    font-weight: 500;

    @include desktop-1366{
      font-size: 1rem;
    }
  }
  form#RequestForm {
    background: #bf9f6d;
    padding: 20px 45px;
    border: 1px solid $white;
    border-radius: 10px;
    @include screen-767{
      padding: 20px 15px;
    }
    }
  .request-form input,
  .request-form select {
    /* border-color: $white; */
    border: none;
    border-bottom: 1px solid $white;
    width: 100%;
    background: transparent;
    padding: 15px 0;
    margin-bottom: 15px;
    font-size: 1.3em;
    -webkit-appearance: none;
    outline: none;
    color: #fff;
    
    @include desktop-1366{
      font-size: 1rem;
    }
  }
  input.custom-input {
    padding: 5px 10px;
}
  .request-form textarea{
    border: 1px solid $white;
    background: transparent;
    width: 100%;
    max-height: 120px;
    resize: none;
    padding: 10px;
    margin-top: 15px;
    outline: none;
    color: $white;
    font-size: 1.3em;
  }
  .request-form {
    padding-top: 60px;
    padding-bottom: 60px;
    background: $primary-color;
  }
  .modal-dialog {
    max-width: 1200px;
    width: 100%;
  }
  label.MM_label span {
    font-size: 10px;
  }
  
  button.close {
    position: absolute;
    font-size: 57px;
    color: $primary-color;
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    right: 15px;
    top: 0px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    z-index: 9999999 !important;
  }
  button.close span {
    margin-top: -10px;
  }
  
  h1.modal_title {
    text-align: center;
    margin-bottom: 50px;
    color: $primary-color;
  }
  .request-form input[type="submit"] {
    width: auto;
    border: 1px solid transparent;
    display: block;
    padding: 15px 40px;
    text-align: center;
    margin: 15px auto;
    cursor: pointer;
    transition: all 0.4s;
    margin-top: 50px;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    background: $white;
    color: $primary-color;
    font-size: 28px;
    @include screen-767{
      padding: 15px 20px;
    }
  }
  input[type="submit"]:hover {
    background: #fff;
    transition: all 0.4s;
    color: $primary-color;
    border: 1px solid $primary-color;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
  }
  .checkin span,
  .checkout span {
    display: block;
    position: relative;
  }
  
  .checkin i,
  .checkout i {
    position: absolute;
    right: 15px;
    top: 16px;
    font-size: 30px;
    pointer-events: none;
  }
  .person {
    display: inline;
    position: relative;
  }
  
  .person i {
    position: absolute;
    font-size: 30px;
    right: -16px;
    top: 40px;
    pointer-events: none;
    display: none;
  }
  .custom-select:before {
    position: absolute;
    right: 10px;
    top: 3px;
    content: "\f107";
    font-family: "fontawesome";
    font-size: 30px;
    color: #a7a7a7;
    pointer-events: none;
    cursor: pointer;
    background: transparent;
    padding-left: 5px;
    color: $white;
    @media screen and (max-width: 1599px){
      top: -2px;
    }
  }
  .request-form i {
    color: #fff;
  }
  
  .left-photo {
    /* background: url(../images/formbg.jpg) center center no-repeat; */
    background-size: cover;
    /* height: 45vh; */
  }
  .img-box img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    transition: opacity 2s ease-in-out;
    -webkit-transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -ms-transition: opacity 2s ease-in-out;
    -o-transition: opacity 2s ease-in-out;
    animation-name: formFadeInOut;
    animation-timing-function: ease-in-out;
    animation-iteration-count: inherit;
    animation-duration: 7s;
    animation-direction: alternate;
  }
  @keyframes formFadeInOut {
    0% {
      opacity: 1;
    }
    17% {
      opacity: 1;
    }
    25% {
      opacity: 0;
    }
    92% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  .img-box img:nth-of-type(1) {
    animation-delay: 10s;
  }
  .img-box img:nth-of-type(2) {
    animation-delay: 6s;
  }
  .img-box img:nth-of-type(3) {
    animation-delay: 3s;
  }
  /* Custom select dropdown  style */
  .custom-select {
    position: relative;

    @include screen-767{
      max-width: 100% !important;
    }
  }
  
  .custom-select select {
    display: none; /*hide original SELECT element:*/
  }
  
  .select-selected {
    background-color: transparent;
  }

  #reservation_block{
    input, select, textarea{
      border: 1px solid rgba(207, 166, 112, 0.5);
      outline: none;
      margin-bottom: 25px;
      width: 100%;
      height: 50px;
      padding: 15px 20px;
      font-size: 1.1rem;

    }
    input[type="submit"]{
      background: #cfa670;
      max-width: 220px;
      width: 100%;
      margin: 15px auto 0;
      text-align: center;
      cursor: pointer;
      border: none;
      color: #fff;
      font-size: 1.1rem;
      display: block;
      transition: all 0.5s ease;

      &:hover{
        background-color: #b67e35;
      }
    }
    textarea{
      height: 110px;
    }
    .content-box{
      background: #fff;
      box-shadow: 0 2px 10px #ccc;

      @include phone-580{
        padding: 30px 15px !important;
      }
    }

    @include screen-767{
      .pl-15 {
        padding-left: 30px!important;
      }
      .pr-15 {
        padding-right: 30px!important;
      }
    }

  }
  .res-title{
    font-size: 60px;
    color: #1a3853;
    letter-spacing: -4px;
    font-weight: 300;

    @include phone-580{
      font-size: 50px;
    }
  }
  /*style the arrow inside the select element:*/
  /* .select-selected:after {
    position: absolute;
    content: "";
    top: 14px;
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: #fff transparent transparent transparent;
  } */
  
  /*point the arrow upwards when the select box is open (active):*/
  /* .select-selected.select-arrow-active:after {
    border-color: transparent transparent #fff transparent;
    top: 7px;
  } */
  
  /*style the items (options), including the selected item:*/
  .select-items div,
  .select-selected {
    color: $white;
    padding: 15px 16px;
    border: 1px solid transparent;
    border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
    cursor: pointer;
    user-select: none;
    border: none;
    border-bottom: 1px solid $white;
    font-size: 1.25em;
  }
  .custom-select.villa-dropdown .select-items div,
  .custom-select.villa-dropdown .select-selected {
    font-size: 1.3em;
  }
  @media screen and (max-width: 767px){
    .custom-select.villa-dropdown .select-items div,
    .custom-select.villa-dropdown .select-selected {
      font-size: 1.1em;
    }
  }
  /*style items (options):*/
  .select-items {
    position: absolute;
    /* background-color: pink; */
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99999;
  }
  
  /*hide the items when the select box is closed:*/
  .select-hide {
    display: none;
  }
  
  .select-items div:hover,
  .same-as-selected {
    background-color: #ddd;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
  }
  .col-lg-12.custom-column {
    z-index: -1;
  }
  .select-items div {
    color: #444;
    background: #ccc;
    border-color: #ddd;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
  }
  div#ui-datepicker-div {
    z-index: 9999999999 !important;
  }
  .ui-widget-header {
    border: 1px solid $primary-color;
    color: #ffffff;
    background: $primary-color;
  }
  a.ui-state-default.ui-state-active {
    background: #fff;
    color: $primary-color !important;
    border-color: $primary-color !important;
    text-align: center;
  }
  .ui-datepicker-next.ui-corner-all::before {
    content: "\f105";
    font-size: 1.25em;
    font-family: fontawesome;
    right: 10px;
    left: auto;
  }
  .ui-datepicker-prev.ui-corner-all:before {
    content: "\f104";
    font-family: fontawesome;
    left: 9px;
    position: absolute;
    top: 0px;
    font-size: 1.25em;
  }
  .ui-datepicker .ui-datepicker-title {
    margin: 0 2.3em;
    line-height: 1.8em;
    text-align: center;
    font-weight: 500;
    font-size: 1.2em;
  }
  
  .ui-datepicker table {
    width: 100%;
    /* font-size: .9em; */
    border-collapse: collapse;
    margin: 0 0 0.6em;
    font-weight: 400 !important;
  }
  
  .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
    border: 1px solid $primary-color;
    background: $primary-color;
    font-weight: 400;
    color: #ffffff !important;
    font-size: 1.2em;
    text-align: center;
  }
  
  .ui-datepicker td span,
  .ui-datepicker td a {
    display: block;
    padding: 0.3em;
    text-align: right;
    text-decoration: none;
  }
  .ui-widget.ui-widget-content {
    border: 1px solid #a7a7a7;
  }
  .row.custom-row:hover .custom-column {
    z-index: 1;
  }
  /* form SLider */
  .imgBox img {
    max-width: 100%;
    border-radius: 10%;
    -webkit-border-radius: 10%;
    -moz-border-radius: 10%;
    -ms-border-radius: 10%;
    -o-border-radius: 10%;
  }
//   .row.custom_row {
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }

#message{
  color: red;
    font-size: 20px;
    text-align: center;
    position: absolute;
    bottom: 255px;
    left: 50%;
    transform: translateX(-50%);

    @include screen-767{
      bottom: 88px;
    }
}
.request-form #message {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  margin: 0;
  bottom: 120px;
  width: 100%;
}
#message.success-box {
  position: relative;
  top: 0;
}
#success_page {
  padding: 50px 15px 40px 15px;
  // border: 1px solid #cfa670;
  border-radius: 5px;
}
.success-thank {
  font-weight: 400;
  font-size: 1.3em;
  line-height: 1.4em;
  color: #cfa670;
  margin-bottom: 10px;
}
.success{color: #009d00;}
span.optional {
  text-transform: none;
  font-style: italic;
  font-size: 0.75em !important;
  letter-spacing: normal;
}
.success-spam {
	font-size: 1em;
    padding-top: 20px;
    color: #d14b4b;
}
textarea::-webkit-input-placeholder {
    color: #444;
  }
  
  textarea:-moz-placeholder { /* Firefox 18- */
    color: #444;  
  }
  
  textarea::-moz-placeholder {  /* Firefox 19+ */
    color: #444;  
  }
  
  textarea:-ms-input-placeholder {
    color: #444;  
  }
  
  textarea::placeholder {
    color: #444;  
  }

@media screen and (max-width: 767px){
  #message{font-size:17px;;
  }
  .request-form #message {
    bottom: 70px;
  }
}
section.request-form .col-md-5, section.request-form .col-md-7{
  @include iPad-portrait{
    flex: 0 0 100%;
    max-width: 100%;
  }
}
section.welcome-block .col-md-5, section.welcome-block .col-md-7{
  @include iPad-portrait{
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.welcome-block .about-content{
  @include iPad-portrait{
    margin-top: 30px;
  }
}
.form_slider{
  @include iPad-portrait{
    margin-bottom: 30px;
  }
}