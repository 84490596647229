// Inner header
.inner-header{
    height: 80vh;
    display: grid;
    align-items: center;
    justify-content: center;

    @include phone-580{
        height: 60vh;
    }

    .header-title{
        h1{
            color: #fff;
            font-size: 65px;
            letter-spacing: -3px;
            text-transform: uppercase;
            font-family: 'Lato', sans-serif;
            text-align: center;
            font-weight: 300;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 1);
            span{
                display: block;
                font-family: 'Kristi', sans-serif;
                color: rgb(229, 201, 119);
                font-size: 70px;
                line-height: 50px;
                text-transform: initial;

            }

            @include phone-580{
                font-size: 50px;
                margin-top: 60px;
            }
        }
    }

    &#header_menu{
        background: url(../assets/images/header-menu.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
    &#header_gallery{
        background: url(../assets/images/header-gallery.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
    &#header_location{
        background: url(../assets/images/header-location.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
    &#header_contact{
        background: url(../assets/images/header-contact.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
    &#header_reservation{
        background: url(../assets/images/header-request.jpg) no-repeat;
        background-size: cover;
        background-position: center;
    }
}

// Menu block
.menu_block{

    .menu-title{
        h2{
            font-size: 36px;
        }
    }
    .menu-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 60px;

        @include screen-767{
            grid-template-columns: auto;
            grid-gap: 0;
        }
        .single-item{
            display: flex;
            align-items: center;
            margin-bottom: 25px;
            border: 1px solid #ccc;
            padding: 10px 15px;
            border-radius: 3px;

            .menu-thumb {
                border-radius: 50%;
                overflow: hidden;
                max-width: 100px;
                width: 100%;
                margin-right: 20px;

                img{
                    max-width: 100%;
                }
            }
            .menu-details{
                h3{
                    font-size: 18px;
                    font-weight: 700;
                    
                }
                p{
                    font-size: 1rem;
                    line-height: 24px;
                    margin-top: 5px;
                }
            }

        }
    }
}

// Parallax block
.menu-parallax{
    height: 80vh;

    @include screen-767{
        height: 40vh;
    }

    .stater-parallax-bg{
        background: url(../assets/images/menu/seafood-fish.jpg) no-repeat;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
        
    }
    .seafood-parallax-bg{
        background: url(../assets/images/menu/chicken-fillet.jpg) no-repeat;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
    }
    .grill-parallax-bg{
        background: url(../assets/images/menu/veal-pomodoro.jpg) no-repeat;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-attachment: fixed;
    }
}

// wine block
.wine_block{
    height: 250px;
    background: url(../assets/images/menu/Wine-Down-Wednesday-Image.jpg) no-repeat;
    background-size: cover;
    background-position: 50% 90%;
    display: grid;
    align-items: center;
    justify-content: center;

    p{
        color: #fff;
        font-weight: 400;
        font-size: 22px;

        @include screen-767{
            text-align: center;
            padding: 15px;
        }
    }
}


// Location Title
.location_title{
    h1{
        color: #1a3853;
        font-size: 60px;
        font-weight: 300;
        letter-spacing: -4px;

        @include phone-580{
            font-size: 50px;
        }
    }

    .location-text{
        max-width: 920px;
        width: 100%;
        margin: 50px auto 0;
        p{
            font-size: 1.25rem;
            
        }
    }
}

// Map Block
.map_block{

    .map-grid {
        display: grid;
        grid-template-columns: 2fr 3fr;
        margin-bottom: 50px;
        align-items: center;

        @include screen-767{
            grid-template-columns: auto;
        }

        .map-title-wrap{
            background: #1a3853;
            color: #fff;
            display: flex;
            align-items: center;
            height: 100%;
            max-height: 260px;
            padding: 60px 35px;
            margin-right: -60px;
            z-index: 9;
            transition: all 0.5s ease-in-out;

            @include screen-767{
                text-align: center;
                padding: 30px;
                margin-right: 0;
                margin-bottom: 30px;
            }

            h3{
                font-size: 70px;
                line-height: 50px;
                font-family: 'Kristi', sans-serif;
            }
            h2{
                font-weight: 300;
                font-size: 60px;
                letter-spacing: -4px;
            }
            span{
                font-weight: 700;
                font-size: 14px;
                display: block;
            }
            a{
                font-size: 16px;
                font-weight: 400;
            }
        }
        .map{
            height: 500px;
        }
    }

}

// TimeTable Block
.opening_time_block{
    h3{
        font-weight: 300;
        font-size: 30px;
    }

    .timetable-wrap{
        max-width: 380px;
        width: 100%;
        margin: 30px auto 0;
        padding: 15px;
        border: 1px solid #ccc;

        .time-row {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-items: center;
            justify-content: center;
            padding: 8px 0;
            

            p{margin: 0;
                @include phone-580{
                    text-align: center;
                }
            }
        }
    }
}