
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
a:hover {
    color: inherit;
}

body{
    font-size: 16px;
    font-family: 'Lato', sans-serif;
    overflow: hidden;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    font-weight: 400;
    color: #333;
}
p, li {
    font-weight: 400;
    font-size: 17px;
    line-height: 31px;
}
div#main-wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}
h1, h2, h3, h3, h5, h6{
    font-family: 'Lato', sans-serif;
    margin: 0;
    font-weight: 400;
}
a, a:hover{
    text-decoration: none;
}
ul{
    margin: 0;
    padding: 0;
    list-style-type: none;
}
svg{
    height: 100% !important;
}
div#main-wrapper.margin-x3 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}
.fancybox-caption-wrap {display: none;}